import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './assets/styles/index.scss';
import Register from './features/register/Register';
import Login from './features/login/Login';
import PrivacyPolicyPage from './components/pages/Landing/PrivacyPolicy';
import TermsCondations from './components/pages/Landing/TermsCondations';
import Plans from './Pages/Landing/Plans';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Main from './Pages/Landing/Main';
import Layout from './components/Layout/Landing/Layout';
import ProtectedRoute from './components/Layout/System/SystemLayout.jsx';
import SystemMainPage from './Pages/System/SystemMainPage.jsx';
import ErrorPage from './Pages/Landing/ErrorPage.jsx';
import AddUserPage from './Pages/System/AddUser.jsx';
import OnBoarding from './Pages/System/OnBoarding.jsx';
import ProtectedOnBoarding from './components/Layout/System/onBoardingScreens/Layout.jsx';
import FinancialSetup from './Pages/System/FinincalSetup.jsx';
import Revenue from './Pages/System/Setup/Revenue.jsx';
import Funding from './Pages/System/Setup/Funding.jsx';
import Staffing from './Pages/System/Setup/Staffing.jsx';
import DirectExpenses from './Pages/System/Setup/DirectExpenses.jsx';
import InDirectExpenses from './Pages/System/Setup/InDirectExpenses.jsx';
import Assets from './Pages/System/Setup/Assets.jsx';

const getAccessToken = () => {
  return localStorage.getItem('Token');
}

const isAuthenticated = () => {
  return !!getAccessToken();
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />, 
    children: [
      {
        path: '/',
        element: <Main />,
        index: true,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/privacy-and-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/terms-and-contadions',
        element: <TermsCondations />,
      },
      {
        path: '/plans',
        element: <Plans />,
      },
    ],
  },
  {
    element: <ProtectedRoute isAuthenticated={isAuthenticated()} />, 
    children: [
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}`,
        element: <SystemMainPage />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/add-user`,
        element: <AddUserPage />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup`,
        element: <FinancialSetup />,
      },
      
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/revenue`,
        element: <Revenue />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/funding`,
        element: <Funding />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/staffing`,
        element: <Staffing/>,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/direct-expenses`,
        element: <DirectExpenses />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/indirect-expenses`,
        element: <InDirectExpenses />,
      },
      {
        path: `dashboard/${localStorage.getItem('USR_ID')}/financials-setup/assets`,
        element: <Assets />,
      },
      
    ],
  },
  {
    element: <ProtectedOnBoarding isAuthenticated={isAuthenticated()} />, 
    children: [
      {
        path: `onboarding/${localStorage.getItem('USR_ID')}`,
        element: <OnBoarding />      
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
