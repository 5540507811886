import React from 'react';
import '../../../assets/styles/pages/contactUs.scss';
import Lottie from 'lottie-react';
import contacUs from '../../../assets/lottie/Contact-us.json';
import Footer from '../../Layout/Landing/Footer';

const ContactUsSection = () => {
    return (
        <>
            <div className='contactUs-page-style d-flex align-items-center justify-content-center vh-100'>
                <div className="container text-center">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12  p-5">
                            <div className='contact-data'>
                                <h3>We’d love to hear from you.</h3>
                                <p>aallie® is here to provide you with more information, answer any questions you may have and create an effective solution for your instructional needs.</p>
                            </div>

                            <form className="d-flex flex-column align-items-center">
                                <div className="row form-design w-100">
                                    <div className="col ">
                                        <input type="text" className="form-control" placeholder="Name" />
                                    </div>
                                    <div className="col mt-3">
                                        <input type="email" className="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="row mt-3 form-design w-100">
                                    <div className="form-group w-100">
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <button className="btn btn-form-submit mt-3">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUsSection;
