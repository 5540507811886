import "../../../../assets/styles/system/cards.scss";
import { useState } from "react";
import { Spin } from "antd";

const CardQuestioner = ({
  title,
  description,
  responseType,
  responseOptions,
  placeholder,
  onResponseChange,
  loadingSuggestions,
  extraLabel,
  onRegenerate,
  currentStep,
  customStyles = {},
  forceSingleInput = false,
  currency = null,
}) => {
  const [selectedResponse, setSelectedResponse] = useState(
    forceSingleInput ? "" : []
  );

  const handleOptionToggle = (option) => {
    let updatedResponse;

    if (Array.isArray(selectedResponse)) {
      if (responseOptions.includes("Yes") && responseOptions.includes("No")) {
        updatedResponse = [option];
      } else {
        updatedResponse = selectedResponse.includes(option)
          ? selectedResponse.filter((item) => item !== option)
          : [...selectedResponse, option];
      }
    } else {
      updatedResponse = option;
    }

    setSelectedResponse(updatedResponse);
    onResponseChange(updatedResponse);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSelectedResponse(newValue);
    onResponseChange(newValue);
  };

  const cleanOption = (option) => {
    return option.replace(/['"]/g, "");
  };

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        marginTop: "5vh",
        justifyContent: "center",
        alignItems: "center",
        ...customStyles.container,
      }}
    >
      <div className="card-questioner" style={customStyles.card}>
        <span>{title}</span>
        <p>{description}</p>

        {extraLabel && <div className="style-extraInfoLabel mb-2"></div>}

        <div className="response-area">
          {responseType === "button" && (
            <div className="d-flex w-100 flex-wrap gap-1 ">
              {responseOptions.length === 0 && loadingSuggestions ? (
                <div className="col-12">
                  <Spin size="large" />
                </div>
              ) : (
                responseOptions.map((label, index) => (
                  <div className="mb-2" key={index}>
                    <button
                      className={`btn btn-choice ${
                        selectedResponse.includes(label) ? "selected" : ""
                      }`}
                      onClick={() => handleOptionToggle(label)}
                      disabled={loadingSuggestions}
                    >
                      {loadingSuggestions ? <Spin size="small" /> : cleanOption(label)}
                    </button>
                  </div>
                ))
              )}
            </div>
          )}

          {responseType === "input" && (
            <div className="Q7-input">
              <div className="Q7 mb-2">
                <label className="label-option">{placeholder}</label>
                <div className="input-wrapper">
                  {currency && <span className="dollar-icon">{currency}</span>}
                  <input
                    type="number"
                    value={selectedResponse}
                    onChange={handleInputChange}
                    placeholder={"100"}
                    className="input-response"
                    required
                    disabled={loadingSuggestions}
                  />
                </div>
              </div>
            </div>
          )}

          {onRegenerate && (
            <div className="mt-3">
              <button
                className="btn btn-regenerate"
                onClick={onRegenerate}
                disabled={loadingSuggestions || (Array.isArray(selectedResponse) && selectedResponse.length === 0)}
              >
                {loadingSuggestions ? <Spin /> : "Re-generate Options"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardQuestioner;