import "../../../assets/styles/system/cards.scss";
import { useState } from "react";
import { Spin } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CardQuestioner = ({
  title,
  description,
  responseType,
  responseOptions,
  placeholder,
  onResponseChange,
  loadingSuggestions,
  extraLabel,
  onRegenerate,
  currentStep,
}) => {
  const [selectedResponse, setSelectedResponse] = useState(
    responseType === "input" ||
      responseType === "checklist" ||
      responseType === "button"
      ? []
      : {}
  );
  const [inputValues, setInputValues] = useState({});
  const [dateValues, setDateValues] = useState({});
  const [selectedResponses, setSelectedResponses] = useState([]);

  const handleDropdownChange = (optionKey, newValue) => {
    const updatedResponses = {
      ...selectedResponses,
      [optionKey]: newValue,
    };
    setSelectedResponses(updatedResponses);
    onResponseChange(updatedResponses);
  };
  const handleDateChange = (optionKey, date) => {
    const updatedDateValues = {
      ...dateValues,
      [optionKey]: date,
    };
    setDateValues(updatedDateValues);
    onResponseChange(updatedDateValues);
  };
  const isFormValid = () => {
    if (responseType === "input") {
      return Object.keys(inputValues).every((key) => inputValues[key] !== "");
    }
    if (responseType === "button" || responseType === "checklist") {
      return selectedResponse.length > 0;
    }
    if (responseType === "dropdown") {
      return Object.keys(selectedResponses).every(
        (key) => selectedResponses[key] !== ""
      );
    }
    return true;
  };
  const handleResponseChange = (optionKey, newValue) => {
    const updatedInputValues = {
      ...inputValues,
      [optionKey]: newValue || "",
    };

    setInputValues(updatedInputValues);
    onResponseChange(updatedInputValues);
  };

  const handleOptionToggle = (option) => {
    let updatedResponse;

    if (Array.isArray(selectedResponse)) {
      if (responseOptions.includes("Yes") && responseOptions.includes("No")) {
        updatedResponse = [option];
      } else {
        updatedResponse = selectedResponse.includes(option)
          ? selectedResponse.filter((item) => item !== option)
          : [...selectedResponse, option];
      }
    } else {
      updatedResponse = [option];
    }

    setSelectedResponse(updatedResponse);
    onResponseChange(updatedResponse);
  };

  const cleanOption = (option) => {
    return option.replace(/['"]/g, "");
  };

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        marginTop: "5vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="card-questioner">
        <span>{title}</span>
        <p>{description}</p>

        {extraLabel && <div className="style-extraInfoLabel mb-2"></div>}

        <div className="response-area">
          {responseType === "button" && (
            <div className="d-flex w-100 flex-wrap gap-1 ">
              {responseOptions.length === 0 && loadingSuggestions ? (
                <div className="col-12">
                  <Spin size="large" />
                </div>
              ) : (
                responseOptions.map((label, index) => (
                  <div className="mb-2" key={index}>
                    <button
                      className={`btn btn-choice ${
                        selectedResponse.includes(label) ? "selected" : ""
                      }`}
                      onClick={() => handleOptionToggle(label)}
                      disabled={loadingSuggestions}
                    >
                      {loadingSuggestions ? (
                        <Spin size="small" />
                      ) : (
                        cleanOption(label)
                      )}
                    </button>
                  </div>
                ))
              )}
            </div>
          )}

          {responseType === "checklist" && (
            <div className="d-flex w-100 flex-wrap gap-1 ">
              {responseOptions.map((option, index) => (
                <div className="col-12" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedResponse.includes(option)}
                      onChange={() => handleOptionToggle(option)}
                      required
                    />
                    {cleanOption(option)}
                  </label>
                </div>
              ))}
            </div>
          )}

          {responseType === "dropdown" &&
            Array.isArray(selectedResponse) &&
            selectedResponse.length > 0 && (
              <div className="d-flex w-100 flex-wrap gap-1">
                {selectedResponse
                  .filter((option) => option !== "Yes" && option !== "No")
                  .map((selectedOption, index) => (
                    <div className="Q6 mb-2" key={index}>
                      <label className="label-option">
                        {cleanOption(selectedOption)}
                      </label>

                      <select
                        value={selectedResponses[selectedOption] || ""}
                        onChange={(e) =>
                          handleDropdownChange(selectedOption, e.target.value)
                        }
                        className="response-dropdown"
                        required
                        disabled={loadingSuggestions}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {responseOptions.map((option, idx) => (
                          <option key={idx} value={cleanOption(option)}>
                            {cleanOption(option)}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
              </div>
            )}

          {responseType === "input" && Array.isArray(selectedResponse) && (
            <>
              {currentStep === 8 ? (
                <div className="Q8-input">
                  {selectedResponse
                    .filter((option) => option !== "Yes" && option !== "No")
                    .map((selectedOption, index) => (
                      <div className="Q8 mb-2" key={index}>
                        <label className="label-option">
                          {cleanOption(selectedOption)}
                        </label>
                        <div className="label-responses">
                          <div className="">
                            <span className="Q8-dollar-icon">$</span>
                            <input
                              type="number"
                              value={inputValues[selectedOption] || ""}
                              onChange={(e) =>
                                handleResponseChange(
                                  selectedOption,
                                  e.target.value
                                )
                              }
                              placeholder={"100"}
                              className="Q8input-response"
                              required
                              disabled={loadingSuggestions}
                            />
                          </div>
                          <span className="perMonth">Per Month</span>
                          <span className="from">From</span>

                          <DatePicker
                            selected={dateValues[selectedOption] || null}
                            onChange={(date) =>
                              handleDateChange(selectedOption, date)
                            }
                            className="Q8-input-response"
                            required
                            disabled={loadingSuggestions}
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MMMM-yyyy"
                          />
                              
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="Q7-input">
                  {selectedResponse
                    .filter((option) => option !== "Yes" && option !== "No")
                    .map((selectedOption, index) => (
                      <div className="Q7 mb-2" key={index}>
                        <label className="label-option">
                          {cleanOption(selectedOption)}
                        </label>
                        <div className="input-wrapper">
                          <span className="dollar-icon">$</span>
                          <input
                            type="number"
                            value={inputValues[selectedOption] || ""}
                            onChange={(e) =>
                              handleResponseChange(
                                selectedOption,
                                e.target.value
                              )
                            }
                            placeholder={"100"}
                            className="input-response"
                            required
                            disabled={loadingSuggestions}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}

          {onRegenerate && (
            <div className="mt-3">
              <button
                className="btn btn-regenerate"
                onClick={onRegenerate}
                disabled={loadingSuggestions || !isFormValid()}
              >
                {loadingSuggestions ? <Spin /> : "Re-generate Options"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardQuestioner;
