import React from 'react';
import '../../../assets/styles/components/footer.scss';
import logo from '../../../assets/images/white-logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer container py-4">
        <div className="row  text-md-left">
          {/* First merged column */}
          <div className="col-12 col-md-6 mb-3">
            <div className="row">
              <div className="col-3 col-md-3 mb-3">
                <img 
                  src={logo} 
                  width="100" 
                  height="50" 
                  className="d-inline-block footer-logo align-top" 
                  alt="proFormulate logo" 
                />
              </div>
              <div className="footer-address col-12 col-md-6 mb-3">
                <p className="p-footer">
                  5500 University Pkwy, San Bernardino, CA 92407, United States. <br />
                  +1 (951) 407-0422 <br />
                  info@aallie.com
                </p>
              </div>
            </div>
          </div>

          <div className="col-4 col-md3 mb-3">
            <div className="row">
              <div className="col-12 support col-md-6 mb-3">
                <h5>Support</h5>
                <div className="mt-2 p-footer">
                  <Link to='/privacy-and-policy'><span>Privacy and Policy</span></Link>
                  <br />
                  <Link to='/terms-and-conditions'><span>Terms and Conditions</span></Link>
                </div>
              </div>
              <div className="col-12 company col-md-6 mb-3">
                <h5>Company</h5>
                <div className="mt-2 p-footer">
                  <Link to='/plans'><span>Plans</span></Link>
                  <br />
                  <Link to='/register'><span>Register</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className='text-center mt-2 footer-p'>Copyright © 2024 aallie | Powered by aallie</p>
      </div>
    </>
  );
};

export default Footer;
