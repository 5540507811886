import React from 'react';
import '../../../assets/styles/pages/home.scss';
import Lottie from 'lottie-react';
import user from '../../../assets/lottie/profile.json';
import internet from '../../../assets/lottie/internet.json';
import support from '../../../assets/lottie/support.json';
import { Link } from 'react-router-dom';

const cardData = [
  {
    id: 1,
    animationData: user,
    title: 'User-Friendly interface',
  },
  {
    id: 2,
    animationData: support,
    title: 'Intuitive to Use',
  },
  {
    id: 3,
    animationData: internet,
    title: 'Access via Web Browser',
  },
];

const LandingSection = () => {
  return (
    <>
      <div className="home">
        <div className="container">
          <div className="d-flex vh-75 justify-content-center align-items-center">
            <div className="home-content text-center">
              <h1>CAN YOUR IDEA <br /> MAKE MONEY?</h1>
              <p>We assist business owners in calculating the total cost of creating a product or providing a service, taking into account numerous cost components such as raw materials, labor, and overheads.</p>
              <div className="d-flex justify-content-center">
              <Link to='/register'>
                <button className="btn btn-try-now">Try it now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="cards text-center m-auto">
            <div className="row">
              {cardData.map((card) => (
                <div key={card.id} className="col-12 col-md-4 d-flex justify-content-center">
                  <div className="card mb-3 d-flex justify-content-center">
                    <div className="row no-gutters align-items-center">
                      <div className="col-3">
                        <Lottie className="correct-logo p-3" animationData={card.animationData} />
                      </div>
                      <div className="col-9">
                        <div className="card-body">
                          <h5 className="card-title text-start p-1">{card.title}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSection;
