import React from 'react';
import DirectExpensesComponent from '../../../components/pages/System/Setup/DirectExpensesComponent';


const DirectExpenses = () => {
  return (
    <>
       <DirectExpensesComponent />
    </>
  );
};

export default DirectExpenses;
