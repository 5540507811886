import React, { useState, useEffect } from 'react';
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UserOutlined,
  EditOutlined,
  FolderViewOutlined,
  MoneyCollectOutlined
} from '@ant-design/icons';
import {
  Button,
  Layout,
  Menu,
  Drawer,
  theme,
} from 'antd';

import '../../../assets/styles/components/layout.scss'
import logo from '../../../assets/images/white-logo.png';
import smallLogo from '../../../assets/images/small.png';


const AuthenticatedRoute = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(() => {
    const user_id = localStorage.getItem('USR_ID');
    const user_token = localStorage.getItem('Token');
    return user_id && user_token;
  });

  useEffect(() => {
    const user_id = localStorage.getItem('USR_ID');
    const user_token = localStorage.getItem('Token');
    const isAuthenticated = user_id && user_token;
    setAuthenticated(isAuthenticated);
  }, []);

  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const { Header, Sider, Content } = Layout;

const ProtectedRoute = () => {
  const [visible, setVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  ;

  const userId = localStorage.getItem('USR_ID');
  const navigate = useNavigate();

  const logout = async () => {
      localStorage.removeItem('Token');
      localStorage.removeItem('USR_ID');
      navigate('/');
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <AuthenticatedRoute>

        <Layout >
        {windowWidth >= 768 ? (
          <Sider  trigger={null} collapsible collapsed={collapsed}>
            <div  />
            <div className="p-2 text-white">
              {collapsed ?  <img src={smallLogo} width="50" height="40"  className="mt-3" alt="aallie logo" /> :<img src={logo} width="120" height="35" className='mt-3' alt="aallie logo" /> }
            </div>
            <Menu
              className='left-slide-style'
              mode="inline"
              defaultSelectedKeys={['1']}
              items={[
                
                {
                  key: '1',
                  icon: <HomeOutlined />,
                  label: <Link to={`/dashboard/${userId}`}>Home</Link>,
                },{
                  key: '2',
                  icon: <EditOutlined />,
                  label: <Link to={`/dashboard/${userId}/edit-plan`}>Edit Plan</Link>,
                },
                {
                  key: '3',
                  icon: <FolderViewOutlined />,
                  label: <Link to={`/dashboard/${userId}/view-plan`}>View Plan</Link>,
                },
                {
                  key: '4',
                  icon: <MoneyCollectOutlined />,
                  label: <Link to={`/dashboard/${userId}/financials-setup`}>Financials</Link>,
                },
                {
                  key: '5',
                  icon: <UserOutlined />,
                  label: <Link to={`/dashboard/${userId}/users`}>Users</Link>,
                },
        
        
              ]}
            />
          </Sider>
        ) : (
          <>
             
              <Drawer
                title="Menu"
                placement="left"
                closable={true}
                onClose={onClose}
                open={visible}
                styles={{
                  content: {
                    backgroundColor: '#3D5A80', // Same background color as Sider
                    color: 'white', // Same text color as Sider
                  },
                  header: {
                    backgroundColor: '#3D5A80', // Same background color as Sider
                    color: 'white', // Same text color as Sider
                  },
                }}
              >
                <div  />
            <div className="p-2 text-white">
              {collapsed ?  <img src={smallLogo} width="50" height="40"  className="mt-3" alt="aallie logo" /> :<img src={logo} width="120" height="35" className='mt-3' alt="aallie logo" /> }
            </div>
                <Menu
                  className="left-slide-style"
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  items={[
                    {
                      key: '1',
                      icon: <HomeOutlined />,
                      label: <Link to={`/dashboard/${userId}`}>Home</Link>,
                    },{
                      key: '2',
                      icon: <EditOutlined />,
                      label: <Link to={`/dashboard/${userId}/edit-plan`}>Edit Plan</Link>,
                    },
                    {
                      key: '3',
                      icon: <FolderViewOutlined />,
                      label: <Link to={`/dashboard/${userId}/view-plan`}>View Plan</Link>,
                    },
                    {
                      key: '4',
                      icon: <MoneyCollectOutlined />,
                      label: <Link to={`/dashboard/${userId}/financials-setup`}>Financials</Link>,
                    },
                    {
                      key: '5',
                      icon: <UserOutlined />,
                      label: <Link to={`/dashboard/${userId}/users`}>Users</Link>,
                    },
                  ]}
                />
              </Drawer>
            </>
          )}
          <Layout>
            <Header
              
              className=' header-style '
            >
              <div className="d-flex justify-content-between">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={windowWidth >= 768 ? toggleSider : showDrawer}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
                <button className='mt-3 m-4 btn btn-logout' onClick={logout}>Logout</button>
              </div>
            </Header>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 594,
                background: '#fff',
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container layout-content">
                <Outlet  />
              </div>
            </Content>
          </Layout>
        </Layout>
      </AuthenticatedRoute>

    </>
  );
};

export default ProtectedRoute;