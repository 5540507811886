import React from 'react';
import RevenueComponent from '../../../components/pages/System/Setup/RevenueComponent';


const Revenue = () => {
  return (
    <>
       <RevenueComponent />
    </>
  );
};

export default Revenue;
