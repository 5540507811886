import React from 'react';
import FundingComponent from '../../../components/pages/System/Setup/FundingComponent';


const Funding = () => {
  return (
    <>
       <FundingComponent />
    </>
  );
};

export default Funding;
