    import React, { useState, useEffect } from "react";
    import { message, Steps, Spin, Modal, Typography } from "antd";
    import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
    import CardQuestioner from "../../System/Cards/CardsQuestionerAssets";
    import axios from "axios";

    const { Step } = Steps;
    const { Title } = Typography;

    const AssetsComponent = () => {
        const [questions, setQuestions] = useState([]);
        const [currentStep, setCurrentStep] = useState(0);
        const [userResponses, setUserResponses] = useState({});
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);
        const [showPlanModal, setShowPlanModal] = useState(false);
        const [planContent, setPlanContent] = useState("");

        const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

        useEffect(() => {
            const fetchQuestions = async () => {
                setLoading(true);
                setError(null);

                try {
                    // Load your questions data here, including proper responseType for each question.
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/questions?steps_id=5`
                    );
                    setQuestions(response.data);
                } catch (error) {
                    console.error("Error fetching questions:", error);
                    setError("Failed to load questions from the backend.");
                    message.error("Failed to load questions from the backend.");
                } finally {
                    setLoading(false);
                }
            };

            fetchQuestions();
        }, []);

        const handleResponseChange = (questionId, response) => {
            // Update the response for the specific question
            setUserResponses((prevResponses) => ({
                ...prevResponses,
                [questionId]: response,
            }));
        };

        const saveResponseToDatabase = async (questionId, response) => {
            try {
                const userId = localStorage.getItem("USR_ID");

                if (!userId) {
                    message.error("User ID not found in local storage.");
                    return;
                }

                const payload = {
                    question_id: questionId,
                    answer: response,
                    user_id: parseInt(userId),
                };

                await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/user-responses`,
                    payload
                );
            } catch (error) {
                console.error("Error saving user response:", error);
                message.error("Failed to save user response.");
            }
        };

        const nextStep = () => {
            const currentQuestion = questions[currentStep];
            const currentResponse = userResponses[currentQuestion?.id];

            if (!currentResponse) {
                message.error("Please provide a response before proceeding.");
                return;
            }

            saveResponseToDatabase(currentQuestion.id, currentResponse);

            if (currentStep < questions.length - 1) {
                setCurrentStep(currentStep + 1);
            }
        };

        const prevStep = () => {
            if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
            }
        };

        const handleSubmit = async () => {
            setLoading(true);

            const currentQuestion = questions[currentStep];
            const currentResponse = userResponses[currentQuestion?.id];

            if (!currentResponse) {
                message.error("Please provide a response before submitting.");
                setLoading(false);
                return;
            }

            saveResponseToDatabase(currentQuestion.id, currentResponse);

            const userAnswers = Object.entries(userResponses).map(
                ([questionId, response]) => {
                    const question = questions.find((q) => q.id === parseInt(questionId));
                    return {
                        question: question?.title,
                        userAnswer: response,
                    };
                }
            );

            // Format and display plan content in the modal
            setPlanContent(formatPlanContent(userAnswers));
            setShowPlanModal(true);
            setLoading(false);
        };

        const formatPlanContent = (answers) => {
            return answers
                .map(
                    (answer, index) =>
                        `${index + 1}. Question: "${answer.question}"\nUser Answer: "${answer.userAnswer}"`
                )
                .join("\n");
        };

        const handleModalOk = () => {
            setShowPlanModal(false);
        };

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        {loading && (
                            <div className="loading-container">
                                <Spin indicator={loadingIcon} />
                            </div>
                        )}
                        {error && <p className="text-danger">{error}</p>}

                        {!loading && !error && questions.length > 0 && (
                            <>
                                <Steps current={currentStep} className="hide-on-small">
                                    {questions.map((question) => (
                                        <Step key={question.id} />
                                    ))}
                                </Steps>

                                <div className="style-steps row">
                                    <div className="col-12">
                                        <CardQuestioner
                                            title={questions[currentStep].title}
                                            description={questions[currentStep].description}
                                            responseType={questions[currentStep].responseType}
                                            responseOptions={questions[currentStep].responseOptions}
                                            placeholder={questions[currentStep].placeholder || "Please provide a response"}
                                            onResponseChange={(response) => handleResponseChange(questions[currentStep].id, response)}
                                            currentStep={currentStep}
                                            loadingSuggestions={false}
                                            extraLabel={null}
                                            forceSingleInput={true} // Force single input mode for questions that require a single value
                                            currency={currentStep === 1 ? "%" : "$"} // Set '%' for question number 2 (index 1)
                                            customStyles={{
                                                container: { height: "60vh" }, // Custom styling for AssetsComponent
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-5">
                                    {currentStep > 0 && (
                                        <button
                                            className="btn btn-previous-questioner"
                                            onClick={prevStep}
                                        >
                                            Previous
                                        </button>
                                    )}

                                    {currentStep < questions.length - 1 ? (
                                        <button
                                            className="btn btn-next-questioner"
                                            onClick={nextStep}
                                        >
                                            Next
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-done-questioner"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                    )}
                                </div>

                                {planContent && (
                                    <Modal
                                        title={<Title level={3}>Generated Plan</Title>}
                                        visible={showPlanModal}
                                        onOk={handleModalOk}
                                        closable
                                        footer={null}
                                        bodyStyle={{ padding: "20px", fontSize: "16px" }}
                                    >
                                        <Typography>
                                            <pre>{planContent}</pre>
                                        </Typography>
                                    </Modal>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    export default AssetsComponent;
