import React from 'react';
import { Divider } from "antd";
import '../../../assets/styles/pages/plans.scss';
import Lottie from "lottie-react";
import correct from '../../../assets/lottie/correct.json';

const PlansCard = () => {
    return (
        <div className="plansSection">
            <div className="container">
                <h1 className="text-center">Simple. Affordable Plans for Everyone</h1>
                <Divider />
                <div className="row justify-content-between mt-3">
                    {['FREE TRIAL', 'BASIC', 'FULL ACCESS'].map((plan, index) => (
                        <div className={`col-4 ${index === 1 ? 'card-basic' : 'card-main'}`} key={index}>
                            <h5>{plan} {index === 1 && <span className="title-basic">MOST POPULAR</span>}</h5>
                            <p>
                                {plan === 'FREE TRIAL' && "Begin your one-month free trial and gain comprehensive access to all our services, designed to fully support your needs. "}
                                {plan === 'BASIC' && "Helping companies determine the total cost of producing a good or rendering a service, accounting for various expenses."}
                                {plan === 'FULL ACCESS' && "Giving you the ability to play through different hypothetical scenarios and explore their potential impact on your business."}
                            </p>
                            <h4><span>$ {index === 0 ? '0' : (index === 1 ? '5' : '10')} / </span>month</h4>
                            <button className="btn btn-card-trial">Get Started</button>
                            <div className="row">
                                <div className="col-4">
                                    <Lottie className="correct-logo" animationData={correct} />
                                </div>
                                <div className="col-8">
                                    <h3>Hello</h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlansCard;
