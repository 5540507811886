import React from 'react';
import InDirectExpensesComponent from '../../../components/pages/System/Setup/InDirectExpensesComponent';


const InDirectExpenses = () => {
  return (
    <>
       <InDirectExpensesComponent />
    </>
  );
};

export default InDirectExpenses;
