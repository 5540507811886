import React from 'react';
import ServicesSection from '../../components/pages/Landing/ServicesSection';


const Services = () => {
  return (
    <>
      <section>
       <ServicesSection/>
      </section>
    </>
  );
};

export default Services;
