import React, { useEffect, useState } from 'react';
import { Modal, Divider, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import welcomeIMG from '../../../assets/images/welcomeScreen.jpg';
import '../../../assets/styles/system/main.scss';
import InitialSetup from '../../../assets/images/Initial Setup.jpg';
import CompleteSections from '../../../assets/images/Complete Sections.jpg';
import FinicalProjection from '../../../assets/images/Finical projection.jpg';
import DownLoadSetup from '../../../assets/images/downloadSetup.jpg';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const SystemMainComponent = () => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const userName = localStorage.getItem('user_name') || 'User';
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const welcomeMessageShown = localStorage.getItem('welcome_message_shown');
    if (welcomeMessageShown === 'false') {
      setShowWelcomeMessage(true);
      localStorage.setItem('welcome_message_shown', 'true');
    }
  }, []);

  const handleOk = () => {
    setShowWelcomeMessage(false);
  };

  const goToInitialSetup = () => {
    setActiveTabKey("2"); 
  };

  const completeSection = () => {
    setActiveTabKey("3"); 
  };

  const FinanicalSection = () => {
    setActiveTabKey("4"); 
  };

  const DownloadSection = () => {
    setActiveTabKey("5"); 
  };




  return (
    <div>
      <Modal
        className='modelStyle'
        title='aallie'
        visible={showWelcomeMessage}
        onOk={handleOk}
        closable={true}
        closeIcon={<CloseOutlined style={{ color: '#3d5a80', fontSize: '20px' }} />}
        onCancel={() => setShowWelcomeMessage(false)}
        footer={[]}
        style={{ zIndex: 999 }}
      >
        <Tabs activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
          <TabPane tab="Create account" key="1">
            <div className='text-center text-model'>
              <h2>Hello <span>{userName}</span></h2>
              <p style={{ padding: '0 100px', fontSize: '16px', lineHeight: '1.6' }}>
                Whether you're launching a startup, or growing an existing business, we're here to support your professional journey.
                Our platform is designed to help you plan effectively and achieve your goals.
              </p>
              <button className='btn btn-primary-model' onClick={goToInitialSetup}>Continue Business Plan</button>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: '300px' }}>
              <img src={welcomeIMG} alt="Welcome" style={{ width: '100%', height: '66%', objectFit: 'contain' }} />
            </div>
          </TabPane>

          <TabPane tab="Initial Setup" key="2">
            <div className="row modal-style">
              <div className="col-6">
                <div className='vh-modal d-flex align-items-center'>
                  <div>
                    <h4>Answer questions about your Financial and the type of Financial. We can then generate the relevant Chapters and Sections for you to complete.</h4>
                    <br />
                    <p>The plan structure will be tailored to meet your unique requirements, encompassing all the essential sections to showcase your business to its fullest potential.</p>
                    <br />
                    <p>TIP: You have the flexibility to modify the type of plan required at any time, and the structure will be automatically adapted as necessary.</p>
                    <button className='btn btn-welcome-model' onClick={completeSection}>Continue</button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <img src={InitialSetup} alt="Initial Setup" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Complete Sections" key="3">
            <div className="row modal-style">
              <div className="col-6">
                <div className='vh-modal d-flex align-items-center'>
                  <div>  
                    <h4>Fill out the questionnaire provided for each section and we will automatically generate the content based on your answers.</h4>
                    <br />
                    <p>TIP: We are confident that you will be impressed with the content generated, as it is based on hundreds of data points provided by you. However, please remember that you have the flexibility to further enhance the content by editing it and incorporating additional charts, images, and text.</p>
                   
                  
                   <button className='btn btn-welcome-model' onClick={FinanicalSection}>Continue</button>
                  
                  </div>
                </div>
              </div>
              <div className="col-6">
                <img src={CompleteSections} alt="Complete Sections" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Finical projection" key="4">
            <div className="row modal-style">
              <div className="col-6">
                <div className='vh-modal d-flex align-items-center'>
                  <div>
                    <h4>Now for the best part...aallie will guide you through some financial questions and create your financial projections, charts and tables.</h4>
                    <br />
                    <p>TIP: After you finish the financials for the first time, take a moment to review the numbers and charts. If necessary, make adjustments to the answers provided and re-generate more accurate / realistic figures.</p>
                    <button className='btn btn-welcome-model' onClick={DownloadSection}>Continue</button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <img src={FinicalProjection} alt="Financial Projection" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Download plan" key="5">
            <div className="row modal-style">
              <div className="col-6">
                <div className='vh-modal d-flex align-items-center'>
                  <div>
                    <h4>The last step is to download the finished document, it really couldn't be any easier!</h4>
                    <br />
                    <p>TIP: The PDFs generated are exceptional; you can even change the colors and fonts to match your branding. And if you wish to make more substantial adjustments, you can choose to download it in Word format.</p>
                    <Link to={`/dashboard/${userId}/financials-setup`}>
                    <button className='btn btn-welcome-model' >Go</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <img src={DownLoadSetup} alt="Financial Projection" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default SystemMainComponent;
