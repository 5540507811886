import React from 'react';
import QuestionerComponent from '../../components/pages/System/OnBoardingComponents';

const OnBoarding = () => {
  return (
    <>
       <QuestionerComponent />
    </>
  );
};

export default OnBoarding;
