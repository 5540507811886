import React from 'react';
import StaffingComponent from '../../../components/pages/System/Setup/StaffingComponent';


const Staffing = () => {
  return (
    <>
       <StaffingComponent />
    </>
  );
};

export default Staffing;
