import React, { useState, useEffect } from 'react';
import { Form, Select, Spin, Input, message, Steps } from 'antd';
import axios from 'axios';
import '../../../assets/styles/components/onBoarding.scss';
import Lottie from 'lottie-react';
import questionerLottie from '../../../assets/lottie/questioner.json';
import prompts from '../../../data/prompts.json'; 

const { TextArea } = Input;

const QuestionerComponent = () => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    ONBOARDING_SELECTED_OPTION: '', 
    ONBOARDING_SELECTED_COUNTRY: 'United States',
    ONBOARDING_BUSSINES_DES: '',
    ONBOARDING_BUSSINES_NAME: '',
    ONBOARDING_SELECTED_AiSUGGESTION: '',
  });
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [aiSuggestions, setAiSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [businessNameSuggestions, setBusinessNameSuggestions] = useState([]);
  const [loadingNameSuggestions, setLoadingNameSuggestions] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        const userId = localStorage.getItem('USR_ID');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/onboarding-status/${userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (response.data.flag) {
          message.info('You have already completed the onboarding process.');
          
          setTimeout(() => {
              window.location.href = `/dashboard/${userId}`;
          }, 2000);
        }
      } catch (error) {
        console.error('Failed to check onboarding status:', error);
        message.error('Failed to check onboarding status. Please try again later.');
      }
    };

    checkOnboardingStatus();

    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data
          .map((country) => ({
            name: country.name.common,
            code: country.cca2,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        
        setCountries(countryData);

        const defaultCountry = countryData.find(country => country.name === "United States");
        if (defaultCountry) {
          setFormData(prevFormData => ({
            ...prevFormData,
            ONBOARDING_SELECTED_COUNTRY: defaultCountry.name,
          }));
          form.setFieldsValue({ selectedCountry: defaultCountry.name });
        }

      } catch (error) {
        console.error('Error fetching countries:', error);
        message.error('Failed to load countries. Please try again later.');
      } finally {
        setLoadingCountries(false);
      }
    };

    fetchCountries();
  }, [form]);

  const handleInputChange = (field, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: value
    }));
  };

  const handleLoadAISuggestions = async () => {
    if (!formData.ONBOARDING_BUSSINES_DES) {
      message.error('Please provide a business description first.');
      return;
    }

    setLoadingSuggestions(true);

    const prompt = prompts.onboarding.loadAiSuggestions.replace("{businessDescription}", formData.ONBOARDING_BUSSINES_DES);

    try {
      const result = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;
      suggestions = suggestions
        .split('\n')
        .map(suggestion => suggestion.replace(/^-/, '').trim())
        .filter(suggestion => suggestion);

      setAiSuggestions(suggestions);
    } catch (error) {
      message.error('Failed to load AI suggestions.');
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleLoadBusinessNameSuggestions = async () => {
    if (aiSuggestions.length === 0) {
      message.error('Please load AI suggestions first.');
      return;
    }

    setLoadingNameSuggestions(true);

    const prompt = prompts.onboarding.loadBusinessNameSuggestions.replace("{aiSuggestions}", aiSuggestions.join(', '));

    try {
      const result = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      let suggestions = result.data.choices[0].message.content;
      suggestions = suggestions
        .split('\n')
        .map(suggestion => suggestion.replace(/^\d+\.\s*/, '').trim())
        .filter(suggestion => suggestion);

      setBusinessNameSuggestions(suggestions);
    } catch (error) {
      message.error('Failed to load business name suggestions.');
    } finally {
      setLoadingNameSuggestions(false);
    }
  };
  const handleBusinessNameSelect = (name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ONBOARDING_BUSSINES_NAME: name
    }));
    form.setFieldsValue({ businessName: name });
  };

  const handleAISuggestionSelect = (suggestion) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ONBOARDING_SELECTED_AiSUGGESTION: suggestion
    }));
    localStorage.setItem('ONBOARDING_SELECTED_AiSUGGESTION', suggestion); 
  };

  const handleSubmit = async () => {
    if (Object.values(formData).some(value => !value)) {
      message.error('Please fill in all the required fields.');
      return;
    }
  
    setLoading(true);
  
    try {
      const userId = localStorage.getItem('USR_ID');  
      const payload = {
        ...formData,
        USR_ID: userId
      };
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/onboarding`, payload, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });
      message.success('Form submitted successfully!');
      
      // Reset the welcome message flag
      localStorage.setItem('welcome_message_shown', 'false');
  
      // Redirect to the dashboard after successful submission
      setTimeout(() => {
        window.location.href = `/dashboard/${userId}`;
      }, 2000);
      
    } catch (error) {
      if (error.response && error.response.status === 409) {
        message.error('This user has already submitted an onboarding response.');
      } else {
        message.error('Failed to submit the form. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const steps = [
    {
      content: (
        <div className="text-center q1-onboarding">
          <h1>Why are you creating a financial plan?</h1>
          <p>The option you choose will not alter the structure of your plan; it just helps us to better understand your goals.</p>
          {['Validate Idea', 'Launch Startup', 'Drive Growth'].map((option) => (
            <button
              key={option}
              className={`btn btn-onBorading-Answers mb-2 ${formData.ONBOARDING_SELECTED_OPTION === option ? 'selected' : ''}`}
              onClick={() => handleInputChange('ONBOARDING_SELECTED_OPTION', option)}
            >
              <span>{option}</span>
              <br />
              {option === 'Validate Idea'
                ? 'To transform a business concept or idea into a structured plan...'
                : option === 'Launch Startup'
                  ? 'To create a roadmap for launching, scaling or funding a new business...'
                  : 'To develop strategies for expanding operations...'}
            </button>
          ))}
        </div>
      ),
    },
    {
      content: (
        <div className="scrollable-buttons-container">
          <div className="text-start q1-onboarding">
            <h1 className="text-center">Where is the business based?</h1>
            <p>If the business will operate internationally, select the country where the head office will be situated.</p>
            <Form.Item>
              {loadingCountries ? (
                <Spin />
              ) : (
                <Select
                  className="selection"
                  placeholder="Please select"
                  value={formData.ONBOARDING_SELECTED_COUNTRY}
                  onChange={(value) => handleInputChange('ONBOARDING_SELECTED_COUNTRY', value)}
                >
                  {countries.map((country) => (
                    <Select.Option key={country.code} value={country.name}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>

          <div className="text-start q1-onboarding mt-1">
            <h1 className="text-center">What does the business do?</h1>
            <p>
              This will not appear in your plan; it just helps us understand the business. Below are a few examples:
              <ul className="custom-bullets">
                <li>We are a supplier of specialist glass for use in commercial buildings.</li>
                <li>We are a marketing agency specializing in TikTok and YouTube.</li>
              </ul>
            </p>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Please provide a business description' }]}
            >
              <TextArea
                value={formData.ONBOARDING_BUSSINES_DES}
                onChange={(e) => handleInputChange('ONBOARDING_BUSSINES_DES', e.target.value)}
                placeholder="e.g. We sell electronics in-store and online, we specialize in high-end laptops."
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
            <div className="text-start ">
              {formData.ONBOARDING_BUSSINES_DES && (
                <button
                  className="btn btn-suggest w-100"
                  onClick={handleLoadAISuggestions}
                  disabled={loadingSuggestions}
                >
                  {loadingSuggestions ? 'Loading AI Suggestions...' : 'Load AI Suggestions'}
                </button>
              )}

              {aiSuggestions.length > 0 && (
                <div className="row ai-suggestions mt-3 d-flex justify-content-center">
                  {aiSuggestions.map((suggestion, index) => (
                    <div key={index} className="col-6 col-md-6 col-lg-6 mb-3 d-flex justify-content-center">
                      <button
                        className={`btn btn-onBorading-Answers w-100 ${formData.ONBOARDING_SELECTED_AiSUGGESTION === suggestion ? 'selected' : ''}`}
                        onClick={() => handleAISuggestionSelect(suggestion)}
                      >
                        <h4>{suggestion}</h4>
                      </button>
                    </div>
                  ))}
                </div>
              )}

            </div>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className="scrollable-buttons-container">
          <div className="text-start q1-onboarding mt-1">
            <h1 className="text-center">What is the business called?</h1>
            <p>If you haven't chosen a name, enter a temporary one and change it later in the Business Settings.</p>
          </div>

          <div className="row">
            <div className="col-7">
              <Form.Item name="businessName" rules={[{ required: true, message: 'Please provide a business name' }]}>
                <Input
                  value={formData.ONBOARDING_BUSSINES_NAME}
                  onChange={(e) => handleInputChange('ONBOARDING_BUSSINES_NAME', e.target.value)}
                  placeholder="e.g. aallie"
                />
              </Form.Item>
            </div>
            <div className="col-1 text-center pt-1">Or</div>
            <div className="col-4">
              <button className='btn btn-suggest w-100' onClick={handleLoadBusinessNameSuggestions} >
                {loadingNameSuggestions ? 'Loading Name Suggestions...' : 'Suggest Name'}
              </button>
            </div>
            {businessNameSuggestions.length > 0 && (
              <div className="name-suggestions row mt-3 d-flex justify-content-center">
                {businessNameSuggestions.map((suggestion, index) => (
                  <div key={index} className="col-6 col-md-6 col-lg-6 mb-3 d-flex justify-content-center">
                    <button
                      className={`btn btn-onBorading-Answers w-100 ${formData.ONBOARDING_BUSSINES_NAME === suggestion ? 'selected' : ''}`}
                      onClick={() => handleBusinessNameSelect(suggestion)}
                    >
                      <h4>{suggestion}</h4>
                    </button>
                  </div>
                ))}
              </div>
            )}

          </div>
        </div>
      ),
    },
  ];

  const items = steps.map((item, index) => ({
    key: `step-${index}`,
  }));

  return (
    <div className="onBoardingMainStyle">
      <div className="container p-5">
        <div className="row">
          <div className="col-5">
            <Lottie className="register-logo" animationData={questionerLottie} />
          </div>
          <div className="col-7">
            <Steps current={currentStep} items={items} />
            <div className="style-steps">
              {steps[currentStep].content}
            </div>
            <div className="text-center" style={{ marginTop: 24 }}>
              {currentStep > 0 && (
                <button className="btn btn-previous m-2 mt-3" onClick={prevStep}>
                  Previous
                </button>
              )}
              {currentStep < steps.length - 1 && (
                <button
                  className="btn btn-next  m-2 mt-3"
                  onClick={nextStep}
                  disabled={currentStep === 0 && !formData.ONBOARDING_SELECTED_OPTION}
                >
                  Next
                </button>
              )}
              {currentStep === steps.length - 1 && (
                <button
                  className="btn btn-done m-2 mt-3"
                  onClick={handleSubmit}
                  disabled={loading || Object.values(formData).some(value => !value)}
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionerComponent;
