import React from 'react';
import AssetsComponent from '../../../components/pages/System/Setup/AssetsComponent';


const Assets = () => {
  return (
    <>
       <AssetsComponent />
    </>
  );
};

export default Assets;
