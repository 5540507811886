import React from 'react';
import '../../../assets/styles/pages/model.scss';
import Lottie from 'lottie-react';
import correct from '../../../assets/lottie/correct.json';
import model from '../../../assets/lottie/model.json';
import { Link } from 'react-router-dom';

const iconsData = [
  {
    id: 1,
    title: 'Cost ascertainment',
  },
  {
    id: 2,
    title: 'Decision-making',
  },
  {
    id: 3,
    title: 'Cost control and reduction',
  },
];

const ModelSection = () => {
  return (
    <>
      <div className="container model-section">
        <div className="row">
          <div className="col-12 col-md-6 vh-100 d-flex align-items-center justify-content-center">
            <div className="header">
              <div>
                <span>COSTING MODEL</span>
                <h4>Why use a costing model?</h4>
                <p>There are many reasons to use a costing model. Perhaps the most obvious reason is to generate an accurate estimate of the costs associated with a proposed project. A well-built costing model can provide insights into where the bulk of the costs will be incurred, and what cost-saving measures can be put in place.</p>
              </div>

              <div className="icons row">
                {iconsData.map((icon) => (
                  <div key={icon.id} className={`col-6 model-icon d-flex align-items-center ${icon.id === 3 ? 'col-12' : ''}`}>
                    <Lottie className="correct-logo" animationData={correct} />
                    <h5>{icon.title}</h5>
                  </div>
                ))}
                <div>
                <Link to='/register'>
                  <button className="btn-model">Try it now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 model-image col-md-6">
            <Lottie animationData={model} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelSection;
