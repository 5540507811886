import React from 'react'
import '../../assets/styles/pages/error.scss'
import { Link } from 'react-router-dom';
import error from '../../assets/lottie/error.json'
import Lottie from 'lottie-react';

const ErrorPage = () => {
  return (
    <> 
   <div className='error-div d-flex justify-content-center align-item-center p-5'>
  <div>
  <Lottie animationData={error} className='lotti-error' />
    <Link to={'/'}> 
    <div className='text-center '>
    <button className='error-button m-5' >Back Home</button> 

    </div>
    </Link>
  </div>
   </div>
    </>
    
  )
}

export default ErrorPage
