import React from 'react';
import LandingSection from '../../components/pages/Landing/landingSection';


const Home = () => {
  return (
    <>
      <section>
       <LandingSection/>
      </section>
    </>
  );
};

export default Home;
